<script lang="ts" setup>
const props = defineProps({
  partyReference: String,
});
</script>

<template>
  <v-container fluid class="app-location">
    <header class="app-welcome-message">
      <h1>
        Partij: {{ partyReference }}
        <router-link v-if="$route.name != 'Party' && props.partyReference" :to="{ name: 'Party', params: { reference: props.partyReference } }"> <v-icon>mdi-undo-variant</v-icon></router-link>
        <router-link v-else :to="{ name: 'Parties' }"> <v-icon>mdi-undo-variant</v-icon></router-link>
      </h1>
    </header>

    <router-view></router-view>
  </v-container>
</template>
